import { type useLoaderData } from "@remix-run/react";
import { type ChartArea } from "chart.js";
import toast from "react-hot-toast/headless";
import { BaseError } from "viem";

export type SerializeFrom<T> = Awaited<ReturnType<typeof useLoaderData<T>>>;

export const MAX_SUPPORTED_VERSION = "1.8.0";

/**
 * Handle write contract error
 */
export function handleError(error: Error) {
  if (error.message.includes("User rejected")) {
    return;
  }

  console.error(error);

  if (error instanceof BaseError) {
    toast.error(error.shortMessage);
  } else {
    toast.error(error.message);
  }
}

/**
 * Check if value is truthy with type narrowing
 */
export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value;
}

/**
 * Get server initials
 */
export function getInitials(name: string) {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.shift() || "")).toUpperCase();
}

/**
 * Create gradient for chart
 */
export function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, "rgb(255, 148, 45)");
  gradient.addColorStop(0.1925, "rgb(233, 247, 90)");
  gradient.addColorStop(0.3916, "rgb(178, 224, 55)");
  gradient.addColorStop(0.5354, "rgb(107, 204, 230)");
  gradient.addColorStop(0.6527, "rgb(210, 210, 210)");
  gradient.addColorStop(0.8407, "rgb(231, 178, 128)");
  gradient.addColorStop(1, "rgb(223, 127, 242)");

  return gradient;
}

export function camelCaseToSentenceCase(str: string) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());
}

export function isMobile() {
  if (typeof window === "undefined") return false;

  const { userAgent, navigator } = window as unknown as {
    userAgent: string;
    navigator: Navigator;
  };
  const maxTouchPoints = navigator.maxTouchPoints ?? 0;

  const mobileUserAgentRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobileUserAgent = mobileUserAgentRegex.test(userAgent);

  const hasTouch = maxTouchPoints > 0;

  const isSmallScreen = window.innerWidth <= 768;

  return isMobileUserAgent || (hasTouch && isSmallScreen);
}
